@font-face {
  font-family: "Lato";
  src: url("/fonts/Lato Regular.woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Lato";
  src: url("/fonts/Lato Semibold.woff2");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Lato";
  src: url("/fonts/Lato Bold.woff2");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Lato";
  src: url("/fonts/Lato Bold.woff2");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Lato";
  src: url("/fonts/Lato Black.woff2");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Lato";
  src: url("/fonts/Lato Black.woff2");
  font-weight: bolder;
  font-style: normal;
}

@font-face {
  font-family: "Lato";
  src: url("/fonts/Lato Regular.woff2");
  font-weight: light;
  font-style: normal;
}

@font-face {
  font-family: "Lato";
  src: url("/fonts/Lato Regular.woff2");
  font-weight: lighter;
  font-style: normal;
}

@font-face {
  font-family: "Lato";
  src: url("/fonts/Lato Italic.woff2");
  font-weight: normal;
  font-style: italic;
}

a {
  color: inherit;
  text-decoration: none;
}

body {
  padding: 0px;
  margin: 0px;
}

main {
  /* height: 2000px; */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-block-start: 0;
  margin-block-end: 0;
}

.slick-dots.custom-slider-dots {
  margin-top: 16px;
}

.slick-dots.custom-slider-dots li {
  width: 40px !important;
}

.slick-dots.custom-slider-dots .slick-active {
  width: 66px !important;
}

.slick-dots.custom-slider-dots li button:before {
  height: 6px;
  background-color: rgba(0, 0, 0, 0.2) !important;
  border-radius: 4px;
  content: "";
  margin-right: 40px;
}

.slick-dots.custom-slider-dots .slick-active button:before {
  background-color: #18af4e !important;
  width: 52px !important;
  margin-right: 92px !important;
}
